import React from 'react'
import './Nav.css'

function Nav() {
  return (
    <div className='na'>
        <div className="icc">
        <i class="fa-solid fa-user"></i>
            <i class="fa-solid fa-magnifying-glass"></i>
            <i class="fa-solid fa-house"></i>
            <i class="fa-solid fa-tv"></i>
        </div>
      
    </div>
  )
}

export default Nav